import React, { Component } from "react";
import Layout from "../components/layout"
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from "gatsby";
import SEO from "../components/seo"
import hash from "../images/hash.svg"
class PostTemplate extends Component {
  constructor(props) {
   super(props);

   this.state = { copySuccess: 'Copy Link' }
 }

 copyToClipboard = (e) => {
   const el = this.textArea;
   el.select();
   document.execCommand('copy');
   e.target.focus();
   this.setState({ copySuccess: 'Copied!' });
 };

  componentDidMount() {
    var links = document.querySelectorAll( '.post-body a' );
     for (var i = 0; i < links.length; i++) {
       if (links[i].hostname !== window.location.hostname) {
           links[i].target = '_blank';
       }
     }
  }

  render() {
    const data = this.props.data.contentfulPost;
    const postDate = new Date(data.date);
    const previousFormattingDate = new Date("Sat May 30 2020 20:00:00 GMT-0400")
    const newFormattingClass = postDate <= previousFormattingDate ? "old" : "new";
    const titletext = data.title;
    let newtitletext;
    if (titletext) {
      newtitletext = titletext.toString();
      newtitletext = newtitletext.replace("#", `<span class='hash'></span> `);
    }

    return (
      <Layout>
        <SEO title={`futurefeed | ${data.title}`} image={`https:${data.homepageThumbnail.file.url}`} />
        <div className={`post-page ${newFormattingClass}`}>
          <h2 dangerouslySetInnerHTML={{ __html: newtitletext }} ></h2>
          {data.noContributorsAlternateText &&
            <p className="alt-contrib-text">{data.noContributorsAlternateText}</p>
          }
          {!data.noContributorsAlternateText &&
            <a className="contrib" href={`/contributors/${data.contributor[0].slug}`}>{data.contributor[0].name}</a>
          }
          {!data.noContributorsAlternateText && data.contributor[1] &&
            <span>
              <span class="spacer">&</span>
              <a className="contrib" href={`/contributors/${data.contributor[1].slug}`}> {data.contributor[1].name}</a>
            </span>
          }

          <div className="post-tags">
            {data.tags.map((tag, i) => {
              return (
                <a className="tag" href={`/tags/${tag.slug}`} key={i}><span>{tag.name}</span></a>
              )
            })}
          </div>
          <div className="post-body">
            {documentToReactComponents(data.childContentfulPostMainContentRichTextNode.json, {
              renderMark: {
                [MARKS.CODE]: text => {
                  if (text) {

                    if (text.toString().indexOf('HASHTAG') > -1) {
                      return (
                        <span className="hash" />
                      )
                    } else {
                      let newtext = text.toString();
                      newtext = newtext.replace(/\[<br>\]/g, "<br/>");
                      newtext = newtext.replace(/\[hash\]/g, "<span class='hash'></span>");
                      newtext = newtext.replace(/\[spacer\]/g, "<span class='spacer'></span>");
                      newtext = newtext.replace(/\[bigspacer\]/g, "<span class='spacer big'></span>");

                      return (
                        <span className="poem"
                          dangerouslySetInnerHTML={{ __html: newtext }}
                        />
                      )
                    }
                  }

                }
              },
              renderNode: {
                [BLOCKS.HEADING_2]: (node, children) => (
                  <h2>{children}</h2>
                ),
                [BLOCKS.EMBEDDED_ASSET]: node => {
                  if (node.data.target.fields) {
                    if (node.data.target.fields.file['en-US'].contentType === "application/pdf") {
                      return (
                        <a className="pdf" rel="noopener noreferrer" target="_blank" href={node.data.target.fields.file['en-US'].url}>{node.data.target.fields.file['en-US'].fileName}</a>
                      )
                    } else {
                      console.log(node.data.target);
                      return (
                        <img alt={node.data.target.fields.title["en-US"]} src={node.data.target.fields.file["en-US"].url} />
                      )
                    }
                  }
                },
                [INLINES.EMBEDDED_ENTRY]: node => {
                  if (node.data.target.fields) {
                    if (node.data.target.fields.videoLink) {
                      return (
                        <span>
                          {node.data.target.fields.title &&
                            node.data.target.fields.title["en-US"]
                          }
                          <br/>
                          <span className="video">
                            <iframe title="video" src={`https://player.vimeo.com/video/${node.data.target.fields.videoLink['en-US']}`} frameBorder="0" allowFullScreen></iframe>
                          </span>
                        </span>
                      )
                    } else if (node.data.target.fields.soundcloudTrackId) {
                      return (
                        <span>
                          {node.data.target.fields.title &&
                            node.data.target.fields.title["en-US"]
                          }
                          <br/>
                          <span className="audio">
                            <iframe title="audio" width="100%" height="115" scrolling="no" frameborder="no" allow="autoplay" src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${node.data.target.fields.soundcloudTrackId['en-US']}&color=%000000&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false`}></iframe>
                          </span>
                        </span>
                      )
                    } else if (node.data.target.fields.number) {
                      return (
                        <a className="sup" href={`#${node.data.target.fields.number["en-US"]}`}><sup>{node.data.target.fields.number["en-US"]}</sup></a>
                      )
                    } else if (node.data.target.fields.referenceNumber) {
                      var note =  node.data.target.fields.footnoteReference["en-US"].content[0].content.map((v, i) => {
                        let val = "";
                        if (v.value) {
                          val = v.value.replace(/\n/g, "");
                          if (v.marks.length > 0) {
                            return (
                              <span key={i} className={v.marks[0].type}>{val}</span>
                            )
                          } else {
                            return (
                              <span key={i}>{val}</span>
                            )
                          }
                        }

                      })
                      return (
                        <span className="footnote" id={`${node.data.target.fields.referenceNumber["en-US"]}`}>
                          <span>{node.data.target.fields.referenceNumber["en-US"]}. </span>
                          {note}
                        </span>
                      )
                    } else {
                      return;
                    }
                  }
                },
              },
            })}

            <div className="share">
              <p className="share-title">Share this post</p>

              <a className="share-button" href={`https://twitter.com/intent/tweet?text=${data.title}%20-%20%23futurefeedblog&url=https://future-feed.net/${data.slug}`}>
                Twitter</a>

              <a className="share-button" href={`https://facebook.com/sharer/sharer.php?u=https://future-feed.net/${data.slug}`}>
              Facebook</a>

              <a className="share-button" href={`mailto:?subject=${data.title}%20in%20Futurefeed&body=Hello%2C%0A%0AI%20just%20read%20this%20piece%20on%20Futurefeed%20and%20wanted%20to%20share%20it%20with%20you%3A%0A%0Ahttps%3A%2F%2Ffuturefeed.net%2Farticles%2F${data.slug}%2F`}>
                E-Mail</a>


              <a className="share-button" onClick={this.copyToClipboard}>
                {this.state.copySuccess}

              </a>
              <textarea
                className="copy"
                ref={(textarea) => this.textArea = textarea}
                defaultValue={`https://future-feed.net/${data.slug}`}
              />

            </div>
          </div>
          {data.relatedPosts &&
            <div className="related">
                <p>Related Posts</p>
                {data.relatedPosts.map((post, i) => {
                  const relatedTitle = post.title;
                  let relatedTitleText;
                  if (relatedTitle) {
                    relatedTitleText = relatedTitle.toString();
                    console.log(relatedTitleText);
                    relatedTitleText = relatedTitleText.replace("#", `<span class='hash black'></span> `);
                  }
                  return (
                    <a className="related-post" href={`/${post.slug}`} key={i}><span dangerouslySetInnerHTML={{ __html: relatedTitleText }} ></span></a>
                  )
                })}
            </div>
          }
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query postQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      date
      displayDate
      noContributorsAlternateText
      homepageThumbnail {
        ... on ContentfulAsset {
          file {
            contentType
            url
          }
        }
        fluid(maxWidth: 1800, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
      contributor {
        ... on ContentfulContributor {
          name
          slug
        }
      }
      childContentfulPostMainContentRichTextNode {
        id
        json
      }
      tags {
        ... on ContentfulTag {
          name
          slug
        }
      }
      relatedPosts {
        ... on ContentfulPost {
          title
          slug
        }
      }
    }
  }
`
